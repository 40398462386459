import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import classnames from "classnames";

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock";

import useScrollPosition from "../../hooks/useScrollPosition";
import Button from "../Button/Button";

import "./styles.scss";

let enableScrollTimeout;

const Menu = props => {
  const scroll = useScrollPosition(300);
  const menuContainer = useRef(null);
  const [opened, setOpen] = useState(false);
  const { lang, menu_icon, body: menu_links = [] } = props;

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
      clearTimeout(enableScrollTimeout);
    };
  });

  return (
    <nav
      className={classnames(
        "Menu",
        { opened: opened },
        { "with-shadow": scroll > 40 }
      )}
    >
      <div className="container-md">
        <div>
          <div className="Menu-static">
            {menu_icon && menu_icon.url && (
              <Link to={`/${lang}/`} className="Menu-icon">
                <img src={menu_icon.url} alt={menu_icon.alt} />
              </Link>
            )}
            <button
              className="Menu-toggle"
              onClick={() => {
                if (!opened) {
                  disableBodyScroll(menuContainer.current);
                } else {
                  enableScrollTimeout = setTimeout(() => {
                    enableBodyScroll(menuContainer.current);
                  }, 350);
                }
                setOpen(!opened);
              }}
            >
              <span />
              <span />
              <span />
            </button>
          </div>

          <div className="Menu-in" ref={menuContainer}>
            <ul className="Menu-links">
              {menu_links.map(
                ({ primary: { link_url, link_text }, __typename }) => {
                  switch (__typename) {
                    case "PrismicMenuBodyButton":
                      return (
                        <li key={link_url + link_text}>
                          <Button to={link_url}>{link_text}</Button>
                        </li>
                      );
                      break;
                    default:
                    case "PrismicMenuBodyLink":
                      if (
                        link_url.includes("http") ||
                        link_url.includes("www.") ||
                        link_url.includes("mailto") ||
                        link_url.includes("tel")
                      ) {
                        return (
                          <li key={link_url + link_text}>
                            <a
                              className="Menu-link"
                              href={link_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {link_text}
                            </a>
                          </li>
                        );
                      } else {
                        return (
                          <li key={link_url + link_text}>
                            <Link
                              className="Menu-link"
                              activeClassName="active"
                              to={link_url}
                            >
                              {link_text}
                            </Link>
                          </li>
                        );
                      }
                  }
                }
              )}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Menu;
