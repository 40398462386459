import * as React from "react";
import { Link } from "gatsby";

import "./styles.scss";

const Button = ({ onClick, to, children, className = "" }) => {
  const handleClick = e => {
    if (onClick) {
      e.preventDefault();
      onClick(e);
    }
  };

  if (to) {
    if (
      to.includes("http") ||
      to.includes("www.") ||
      to.includes("mailto") ||
      to.includes("tel")
    ) {
      return (
        <a
          className={`Button ${className}`}
          href={to}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>{children}</span>
        </a>
      );
    } else {
      return (
        <Link className={`Button ${className}`} to={to}>
          <span>{children}</span>
        </Link>
      );
    }
  }

  return (
    <button className={`Button ${className}`} onClick={handleClick}>
      <span>{children}</span>
    </button>
  );
};

export default Button;
