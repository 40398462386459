import React from "react";
import { Link } from "gatsby";

import instagramIcon from "../../images/icon-instagram.svg";
import facebookIcon from "../../images/icon-facebook.svg";
import linkedinIcon from "../../images/icon-linkedin.svg";

import "./styles.scss";

export default ({
  lang,
  logo,
  social_instagram_link,
  social_facebook_link,
  social_linkedin_link,
  links,
  bottom_links,
  show_language_switch
}) => (
  <footer className="Footer">
    <div className="container-md">
      <div className="Footer-in">
        {logo && logo.url && (
          <Link to={`/${lang}/`} className="Footer-icon">
            <img src={logo.url} alt={logo.alt} />
          </Link>
        )}

        <ul className="Footer-links">
          {links.map(({ link_url, link_text }) => {
            if (
              link_url.includes("http") ||
              link_url.includes("www.") ||
              link_url.includes("mailto") ||
              link_url.includes("tel")
            ) {
              return (
                <li key={link_url + link_text}>
                  <a
                    className="Footer-link"
                    href={link_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link_text}
                  </a>
                </li>
              );
            } else {
              return (
                <li key={link_url + link_text}>
                  <Link
                    className="Footer-link"
                    activeClassName="active"
                    to={link_url}
                  >
                    {link_text}
                  </Link>
                </li>
              );
            }
          })}
        </ul>
      </div>

      <div className="Footer-social">
        {social_instagram_link && (
          <a
            href={social_instagram_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagramIcon} alt="Instagram icon" />
          </a>
        )}
        {social_facebook_link && (
          <a
            href={social_facebook_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebookIcon} alt="Facebook icon" />
          </a>
        )}
        {social_linkedin_link && (
          <a
            href={social_linkedin_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedinIcon} alt="Linked In icon" />
          </a>
        )}
      </div>
    </div>

    <div className="container-md">
      <div className="Footer-bottom-in">
        <p className="Footer-copyright">
          © {new Date().getFullYear()} EHUB! Coworking
        </p>
        <ul className="Footer-links">
          {show_language_switch === "Yes" && (
            <Link
              className="Footer-link Footer-bottom-link lang-switch"
              to={`/${lang === "es" ? "en" : "es"}`}
            >
              {lang === "es" ? "English" : "Spanish"}
            </Link>
          )}
          {bottom_links.map(({ link_url, link_text }) => (
            <li key={link_url + link_text}>
              <Link
                className="Footer-link Footer-bottom-link"
                activeClassName="active"
                to={link_url}
              >
                {link_text}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </footer>
);
