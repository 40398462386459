import React from "react";
import PropTypes from "prop-types";

import Footer from "../Footer/Footer";
import Menu from "../Menu/Menu";
import CookieMessage from "../CookieMessage/CookieMessage";
// import { isNode } from "../../utils";

import "./styles.scss";

const Layout = ({
  className = "",
  lang,
  children,
  menu,
  footer,
  cookie_message_button_text,
  cookie_message_text
}) => {
  return (
    <div className={`Layout ${className}`}>
      <Menu {...menu} lang={lang} />
      <main>{children}</main>
      <Footer {...footer} lang={lang} />

      <CookieMessage
        cookie_message_button_text={cookie_message_button_text}
        cookie_message_text={cookie_message_text}
      />

      <div id="Video-portal" />
    </div>
  );
};

Layout.propTypes = {
  menu: PropTypes.object,
  footer: PropTypes.object,
  children: PropTypes.node.isRequired
};

export default Layout;
