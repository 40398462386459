import React, {useEffect} from 'react';
import {animated, useSpring} from 'react-spring/web.cjs';

import {isNode} from '../../utils';

import Markdown from '../Markdown/Markdown';
import Button from '../Button/Button';

import './styles.scss';

let showCookieMessageTimeout;
const CookieMessage = ({cookie_message_text = '', cookie_message_button_text = 'Acepto'}) => {
  const [opened, setOpened] = useSpring(() => ({
    from: {y: 50},
  }));

  const showCookieMessage = () => {
    setOpened({y: -100});
  };

  const hideCookieMessage = () => {
    setOpened({y: 50});
  };

  const GTM_SCRIPT_ID = 'ehub-gtm';
  const GTM_HEAD = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-KTH6LWF');
  `;

  const addGTMToHead = () => {
    if (!isNode) {
      if (window.gtag) {
        window.gtag('event', 'page_view', {
          page_title: document.title,
          page_location: window.location.href,
          page_path: window.location.pathname,
        });
        return;
      }

      const scripts = document.getElementsByTagName('script');
      let existsGTMScript = false;
      for (var i = scripts.length; i--; ) {
        if (scripts[i].id === GTM_SCRIPT_ID) {
          existsGTMScript = true;
          break;
        }
      }

      if (!existsGTMScript) {
        const script = document.createElement('script');
        script.innerHTML = GTM_HEAD;
        script.id = GTM_SCRIPT_ID;
        document.head.appendChild(script);
      }
    }
  };

  const acceptCookies = () => {
    hideCookieMessage();
    withCookieAccepted();
    // Save cookie-accepted in localStorage to not show again
    try {
      if (!isNode && window.localStorage) {
        window.localStorage.setItem('cookie-accepted', new Date().toString());
      }
    } catch (e) {
      console.log('No cookies allowed');
    }
  };

  const withCookieAccepted = () => {
    if (process.env.NODE_ENV === 'production') {
      // TODO: Add analytics
      // addGTMToHead();
    } else {
      // Won't add analytics
    }
  };

  useEffect(() => {
    let toShow = true;
    // Only show cookie message if it has not been accepted yet
    try {
      if (!isNode && window.localStorage) {
        toShow = !window.localStorage.getItem('cookie-accepted');
      }
    } catch (e) {
      console.log('No cookies allowed');
    }

    if (toShow) {
      // Cookie not accepted
      showCookieMessageTimeout = setTimeout(() => {
        showCookieMessage();
      }, 2500);
    } else {
      // Cookie alredy accepted
      withCookieAccepted();
    }

    return () => {
      clearTimeout(showCookieMessageTimeout);
    };
  });

  if (!cookie_message_text) return null;
  return (
    <div className="CookieMessage">
      <animated.div
        className="CookieMessage-in"
        style={{
          transform: opened.y.interpolate((y) => `translate3d(0, ${y}%, 0)`),
        }}>
        <div className="container">
          <div className="container-md">
            <div>
              <Markdown input={cookie_message_text.html} />
              <Button onClick={acceptCookies}>{cookie_message_button_text}</Button>
            </div>
          </div>
        </div>
      </animated.div>
    </div>
  );
};

export default CookieMessage;
