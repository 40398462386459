import * as React from 'react';
import parser from 'html-react-parser';
import {Link} from 'gatsby';

import './styles.scss';

const Markdown = ({input, className = '', styleLink = false}) => (
  <div className={`Markdown ${className}`}>
    {parser(input, {
      replace: (domNode) => {
        if (domNode.name === 'a') {
          let hrefFix = domNode.attribs.href;
          if (
            (!domNode.attribs.href.includes('http') || !domNode.attribs.href.includes('.')) &&
            !domNode.attribs.href.includes('mailto')
          ) {
            if (domNode.attribs.href.includes('http') && !domNode.attribs.href.includes('.')) {
              hrefFix = hrefFix.replace('http://', '').replace('https://', '');
            }
            hrefFix = hrefFix.replace('/home', '/').replace('home', '');
          }

          if (hrefFix === '') {
            hrefFix = '/';
          }

          hrefFix = hrefFix.replace('/javascript:', 'javascript:');

          if (
            hrefFix.includes('http') ||
            hrefFix.includes('www.') ||
            hrefFix.includes('mailto') ||
            hrefFix.includes('tel') ||
            hrefFix.includes('javascript:')
          ) {
            return (
              <span
                style={{
                  display: styleLink ? 'block' : 'inline',
                }}>
                <a
                  className={styleLink ? 'Link-generic' : 'no-styled-link'}
                  href={hrefFix}
                  target="_blank"
                  rel="noopener noreferrer">
                  {domNode.children.map(({data, children = []}) => data || children.map(({data}) => data))}
                </a>
              </span>
            );
          }

          return (
            <span
              style={{
                display: styleLink ? 'block' : 'inline',
              }}>
              <Link className={styleLink ? 'Link-generic' : 'no-styled-link'} to={hrefFix}>
                {domNode.children.map(({data}) => data)}
              </Link>
            </span>
          );
        }
      },
    })}
  </div>
);

export default Markdown;
