import React, { useState, useEffect } from "react";
import { throttle } from "throttle-debounce";

import { isNode } from "../utils";

const useScrollPosition = (time = 200) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const scrollHandler = throttle(time, () => {
    setScrollPosition(isNode ? 0 : window.pageYOffset);
  });

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return scrollPosition;
};

export default useScrollPosition;
